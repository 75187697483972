// react
import React from 'react'
import { ParallaxProvider } from 'react-scroll-parallax'
import { HubspotProvider } from '@aaronhayes/react-use-hubspot-form'

// webfont
import '@fontsource/montserrat/700.css'
import '@fontsource/noto-sans-jp/400.css'
import '@fontsource/noto-sans-jp/700.css'
import '@fontsource/noto-sans-jp/900.css'

// reset
import 'sanitize.css'

// variables
import '~/styles/common/variables/_animation.css'
import '~/styles/common/variables/_color.css'
import '~/styles/common/variables/_font.css'
import '~/styles/common/variables/_height.css'
import '~/styles/common/variables/_hover.css'
import '~/styles/common/variables/_transition.css'
import '~/styles/common/variables/_width.css'
import '~/styles/common/variables/_zindex.css'

// base
import '~/styles/common/_base.css'
import '~/styles/common/_helper.css'

export const wrapRootElement = ({ element }) => (
  <ParallaxProvider>
    <HubspotProvider>{element}</HubspotProvider>
  </ParallaxProvider>
)
